import { CdkDrag, CdkDragDrop, CdkDragPlaceholder, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  Pipe,
  PipeTransform,
  ViewEncapsulation,
  computed,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OperationVariables } from '@apollo/client/core';
import { patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  DataReferenceConfig,
  DataReferencePipe,
  ViewComponent,
  ViewConfig,
  ViewSlotDirective,
  viewComponentInputs,
  viewRegistry,
} from '@npm-libs/ng-templater';
import { FilterExpressions, GqlInput, GqlResolverService, RcgListItem } from '@rcg/core';
import { FiltersService, RcgFiltersComponent } from '@rcg/filters';
import { GraphqlClientService } from '@rcg/graphql';
import { ModuleSearchComponent } from '@rcg/standalone';
import { CardSettingsModel, KanbanModule, SortSettingsModel, SwimlaneSettingsModel } from '@syncfusion/ej2-angular-kanban';
import { gql } from 'apollo-angular';
import { EMPTY, catchError, firstValueFrom, from, map, pipe, switchMap, tap } from 'rxjs';
import { HelpdeskItemComponent } from '../../components';
import { ticketMutation } from './kanban.gql';

@Pipe({
  name: 'extractKeys',
  standalone: true,
})
export class ExtractKeysPipe implements PipeTransform {
  transform(value: string): string[] {
    let res = value.split(',');
    res = res.map((el) => el.trim());
    return res;
  }
}

@Pipe({
  name: 'mapData',
  standalone: true,
})
export class MapDataPipe implements PipeTransform {
  transform(value: unknown, columns: columnModel[] | undefined): Record<string, HelpdeskItemModel[]> {
    const data: Record<string, HelpdeskItemModel[]> = {};
    const keys: Record<string, string> = {};
    if (columns) {
      for (const column of columns) {
        const fieldKeys = column.keyField.split(',');
        data[column.keyField] = [];
        for (const key of fieldKeys) {
          keys[key.trim()] = column.keyField;
        }
      }

      if (value) {
        for (const record of value as HelpdeskItemModel[]) {
          const k = keys[record.status.description as string];
          data[k].push(record as HelpdeskItemModel);
        }
      }
    }

    return data;
  }
}

export type HelpdeskItemModel = {
  status_type: string;
  status_id: string;
  color: string;
  id: number;
  tenant: {
    id: number;
  };
  customer: {
    short_name: string;
  };
  contact: {
    full_name: string;
  };
  status: {
    description: string;
    color: string;
  };
  short_description: string;
  owner: {
    full_name: string;
  };
  created_at: string;
  updated_at: string;
  moving?: boolean;
};

const name = 'kanban';
type Name = typeof name;

export type columnModel = {
  keyField: string;
  headerText: DataReferenceConfig;
  allowToggle?: boolean;
  isExpanded?: boolean;
};

export type KanbanViewProps = {
  keyField: string;
  data: DataReferenceConfig;
  sortSettings: SortSettingsModel;
  cardSettings: CardSettingsModel;
  swimlaneSettings?: SwimlaneSettingsModel;
  allowDragAndDrop?: boolean;
  columns: columnModel[];
  stackedHeaders?: {
    text: DataReferenceConfig;
    keyFields: string;
  }[];
  dropboxHeight?: string;
  statusIds: Record<string, number>;
  gql: GqlInput<RcgListItem>;
  filterSettingsId: number;
};

type KanbanGqlState = {
  gql: GqlInput<RcgListItem> | null;
  search: string;
  filters: FilterExpressions | null;
};

export type KanbanViewConfig = ViewConfig<Name, KanbanViewProps>;

@Component({
  standalone: true,
  selector: `rcg-templater-view--${name}`,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: [...viewComponentInputs],
  templateUrl: './kanban.view.component.html',
  styleUrls: ['./kanban.view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [FiltersService],
  imports: [
    CommonModule,
    ViewSlotDirective,
    DataReferencePipe,
    KanbanModule,
    HelpdeskItemComponent,
    CdkDropListGroup,
    CdkDropList,
    CdkDrag,
    MapDataPipe,
    ExtractKeysPipe,
    CdkDragPlaceholder,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    ModuleSearchComponent,
    RcgFiltersComponent,
  ],
})
export class KanbanViewComponent extends ViewComponent<Name, KanbanViewProps, KanbanViewConfig> implements OnInit, AfterViewInit {
  // kanbanData!: Record<string, HelpdeskItemModel[]>;
  // kanbanColumns!: columnModel[];

  dragging: boolean = false;
  canceledByEsc: boolean = false;

  data = signal<RcgListItem[]>([]);

  gqlState = signalState<KanbanGqlState>({ gql: null, search: '', filters: null });

  constructor(
    private changeRef: ChangeDetectorRef,
    private gqlClient: GraphqlClientService,
    private gqlResolver: GqlResolverService,
    public filtersService: FiltersService,
  ) {
    super(changeRef);
  }

  // private readonly filterSettingsId = this.config.props?.filterSettingsId;
  private readonly activeFilters = toSignal(
    this.filtersService.filters$.pipe(map((f) => (this.config ? f[this.config.props!.filterSettingsId] : undefined))),
  );
  readonly filterSettings = computed(() => this.filtersService.filterSettings()[0]);

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.canceledByEsc = true;
      document.dispatchEvent(new Event('mouseup'));
    }
  }

  async ngOnInit() {
    this.filtersService.setFiltersSettings([this.config.props!.filterSettingsId!]);

    const resolved = await this.gqlResolver.resolveGqlInputVariables(this.config.props!.gql);
    patchState(this.gqlState, { gql: resolved });
    this.updateFilters(this.activeFilters);
    this.getKanbanData(this.gqlState);
  }

  private readonly updateFilters = rxMethod<FilterExpressions | undefined>(
    pipe(
      tap((filters) => {
        patchState(this.gqlState, { filters: filters });
      }),
    ),
  );

  private readonly getKanbanData = rxMethod<KanbanGqlState>(
    pipe(
      switchMap((input) => {
        input.gql!['variables'] = { ...input.gql?.variables, search: input.search };

        input.gql!['filters'] = input.filters;

        const resolved = from(this.gqlResolver.resolveGqlInputVariables(input.gql!));

        return resolved;
      }),
      switchMap((input) => {
        const gqlInput = {
          query: gql(input.query as string),
          variables: input.variables as OperationVariables,
        };

        return this.gqlClient.subscribe(gqlInput).pipe(
          tap((data) => {
            this.data.set((data as { data: RcgListItem[] }).data);
          }),
          catchError((err) => {
            console.error('Kanban error', err);
            return EMPTY;
          }),
        );
      }),
    ),
  );

  async drop(event: CdkDragDrop<string[]>) {
    const item = event.item.data;
    const dropStatus = event.container.id;

    if (item.status.description === dropStatus) {
      return;
    }

    if (!this.canceledByEsc) {
      const id = item.id;
      const statusId = this.config.props?.statusIds[dropStatus];

      item.moving = true;
      item.status.description = dropStatus;

      this.data.set([item, ...this.data().filter((e) => e.id !== id)]);

      await firstValueFrom(
        this.gqlClient.mutate<{ data: { id: number; status_id: number } }>({
          mutation: ticketMutation,
          variables: {
            id: id,
            status_id: statusId,
          },
        }),
      );
    } else {
      this.canceledByEsc = false;
    }
  }

  setDragging(dragging: boolean) {
    this.dragging = dragging;
  }

  setExpanded(column: columnModel, expanded: boolean) {
    if (this.config.props?.columns) {
      for (const col of this.config.props.columns) {
        if (col.keyField === column.keyField) {
          col.isExpanded = expanded;
        }
      }
    }
  }

  dropDownListEnter() {
    return false;
  }

  search(search: string) {
    patchState(this.gqlState, { search: search });
  }
}

viewRegistry[name] = {
  component: KanbanViewComponent,
};
